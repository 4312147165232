<template>
  <v-autocomplete
    v-model.number="value"
    :items="options"
    :label="label"
    outlined
    dense
    hide-details
    @change="onChange"
  ></v-autocomplete>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "SelectCountry",
  props: {
    name: {
      type: String,
      default: () => "",
    },
    idItem: {
      type: [Number, String],
      default: () => null,
    },
    idCountry: {
      type: [Number, String],
      default: () => null,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("labels.country") + " *";
      },
    },
  },
  data: () => ({
    value: null,
    options: [],
  }),
  computed: {},
  watch: {
    value() {
      this.onChange();
    },
    idCountry() {
      this.value = this.idCountry;
    },
  },
  mounted() {
    if (this.idCountry) {
      this.value = this.idCountry;
    }
    this.getList();
  },
  methods: {
    onChange: debounce(function () {
      this.$emit("onChange", {
        id: this.idItem,
        name: this.name,
        value: this.value,
      });
    }, 100),
    async getList() {
      const { data } = await httpClient.post("/get-country");
      this.options = data.map((e) => ({
        value: e.id,
        text: this.capitalizeStr(e.name) || "",
      }));
    },
    resetValue() {
      this.value = null;
    },
  },
};
</script>

<style scoped></style>
